import React from 'react';
import styled from 'styled-components';
import { Box, Flex, Text, Link } from 'rebass';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link as GatsbyLink } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import WhiteRoundCard2 from '../../components/client/WhiteRoundCard2';
import AnimatedButtonBig from '../../components/layout/AnimatedButtonBig';

const Pill = styled.div`
  color: ${({ theme, color }) => theme.colors[color] || color || '#3B4B53'};
  background-color: ${({ theme, color }) => theme.colors[color] || color}15;
  border-radius: 24px;
  font-weight: 500;
  font-size: 16px;
  margin: 5px 0px;
  margin: ${({ color }) => (color ? '6px' : '1px')} 0px;
  padding: 5px 16px;
  width: fit-content;
  @media (max-width: 640px) {
    font-size: 14px;
  }
  margin-bottom: 15px;
`;

const AllTalentAdviceButton = styled.button`
  width: fit-content;
  box-sizing: border-box;
  display: ${props => (props.hidden ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  gap: 8px;
  background: #ff6d00;
  border: 2px solid #ffffff;
  border-radius: 100px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ResourcesItem = ({ image, title, description, link }) => (
  <Box width={[1, 1, 1 / 2, 1 / 3]} px={['0px', '20px']} mt={['60px', '60px']} mb='-50px'>
    <WhiteRoundCard2 padding='0px' maxWidth='410px'>
      <Box m={['-30px -30px 0px -30px', '0px']}>
        <GatsbyImage image={image} alt={title} />
      </Box>
      <Box px={['0px', '40px']} pt={['10px', '30px']} pb={['0px', '40px']}>
        <Text
          fontSize='22px'
          color='nextonBlue'
          fontWeight='500'
          lineHeight='1.3'
          letterSpacing='-0.5px'
          my='5px'
          as='h3'>
          {title}
        </Text>
        <Pill color='secondary'>Talent Advice</Pill>
        <Text fontSize='14px' lineHeight='1.6' mb='15px'>
          {description}
        </Text>
        <Link href={link}>
          <AnimatedButtonBig>READ NOW</AnimatedButtonBig>
        </Link>
      </Box>
    </WhiteRoundCard2>
  </Box>
);

const ResourcesList = ({ resourcesImages, buttonHidden }) => (
  <Box className='body-layout' py='30px'>
    <Flex flexWrap='wrap' alignItems='flex-start' pb={['0px', '0px', '0px']} mx='-20px' px='20px' mb='50px'>
      <Box width='100%'>
        {/* <Text fontSize={['40px', '60px']} color="nextonBlue" fontWeight={'500'} mb="30px">
          Heading
        </Text> */}
        <Text fontSize='18px' color='nextonBlack' lineHeight='1.6' letterSpacing='-0.5px'>
          In this section, you'll find compiled the resources that our experts have put together to help you boost your
          skills, build a successful and prosperous career, and land the job you've been working for.
        </Text>
      </Box>
      <ResourcesItem
        image={resourcesImages.report}
        title='2022 Tech Salaries and Benefits Trends: Q4 Report'
        description="Discover the latest salary changes and benefits trends for LATAM tech talent —and what you can expect from recruiters in the region this Q1'23."
        link='/tech-salaries-and-benefits-trends-report'
      />
      <ResourcesItem
        image={resourcesImages.interview}
        title='Interview Preparation'
        description='Get to know the US technical and non-technical interviewing processes —and how you can ace your interviews to get the job of your dreams.'
        link='https://blog.nextonlabs.com/3-steps-to-ace-your-interview'
      />
      <ResourcesItem
        image={resourcesImages.administrative}
        title='Administrative things to know when working remotely'
        description='Deep-dive into all of the available options to get paid as a remote worker; and understand all the administrative ins and outs of remote work from invoicing to payment, compliance, and getting your money in cash!'
        link='https://blog.nextonlabs.com/tag/payment-salary'
      />
      <ResourcesItem
        image={resourcesImages.career}
        title='Career Advancement & Productivity'
        description='Explore different ways in which you can improve your daily productivity while saving time. Also, uncover great tips on advancing and boosting your career.'
        link='https://blog.nextonlabs.com/tag/productivity'
      />
      <ResourcesItem
        image={resourcesImages.tips}
        title='Remote work tips'
        description="Whether you've already been working from home or you're transitioning to it, remote work can present a few challenges. We've prepared tips, life hacks, and improvements for your remote work to boost happiness, work-life balance, and comfort."
        link='https://blog.nextonlabs.com/tag/remote-work'
      />
      <ResourcesItem
        image={resourcesImages.industry}
        title='Starting in the IT industry without Coding'
        description="In line with our purpose of changing lives, we've prepared the right tools, skills, and knowledge needed to excel in the tech industry —without having to code."
        link='/academy'
      />
    </Flex>
    <ButtonContainer>
      <GatsbyLink to='/resources/talent-advice'>
        <AllTalentAdviceButton hidden={buttonHidden}>
          All Talent Advice <FontAwesomeIcon icon={faArrowRight}> </FontAwesomeIcon>
        </AllTalentAdviceButton>
      </GatsbyLink>
    </ButtonContainer>
  </Box>
);

export default ResourcesList;
