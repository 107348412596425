import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Box } from 'rebass';
import Layout from '../../components/layout/Layout';
import MetaData from '../../components/MetaData';
import ResourcesHero from '../../sections/resources/ResourcesHero';
import ResourcesList from '../../sections/resources/ResourcesList';

const camelCase = require('camelcase');

const ResourcesPage = () => {
  const { resources } = useStaticQuery(graphql`
    {
      resources: allFile(filter: { relativeDirectory: { eq: "images/resources" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: NONE, layout: CONSTRAINED, formats: [PNG])
          }
          name
        }
      }
    }
  `);
  const resourcesImages = {};
  resources.nodes.forEach(img => {
    resourcesImages[camelCase(img.name)] = { ...img.childImageSharp.gatsbyImageData };
  });

  return (
    <>
      <MetaData
        title='Resources'
        description='Boosting your career beyond borders'
        image={`https://www.nextonlabs.com${resourcesImages.talentAdviceShareImage.images.fallback.src}`}
      />
      <Layout selected='resources'>
        <ResourcesHero resourcesImages={resourcesImages} />
        <Box className='full-width' bg='nextonLightGrey' mb='75px'>
          <ResourcesList resourcesImages={resourcesImages} buttonHidden />
        </Box>
      </Layout>
    </>
  );
};

export default ResourcesPage;
