import React from 'react';
import { Box, Flex, Text } from 'rebass';
import { GatsbyImage } from 'gatsby-plugin-image';

const ResourcesHero = ({ resourcesImages }) => (
  <Box>
    <Flex flexWrap='wrap' pt={['30px', '75px']} pb='75px'>
      <Box width={[1, 1, 4 / 10, 29 / 80]} px={['0px', '20px']} mt={['0px', '0px', '60px']} mb='50px'>
        <Text
          color='nextonDarkGrey'
          fontSize={['24px', '28px']}
          fontWeight='500'
          lineHeight='1.2'
          letterSpacing='-0.5px'
          mb={['40px', '60px']}>
          Talent Advice
        </Text>
        <Text
          color='nextonBlue'
          fontSize={['40px', '60px']}
          fontWeight='500'
          lineHeight='1.1'
          letterSpacing='-1px'
          as='h1'>
          Boosting your career{' '}
          <Text as='span' color='nextonOrange'>
            beyond borders
          </Text>
        </Text>
      </Box>
      <Box width={[1, 1, 6 / 10, 51 / 80]} px={['0px', '20px']} textAlign='center'>
        <GatsbyImage image={resourcesImages.hero} alt='Our Best' />
      </Box>
    </Flex>
  </Box>
);

export default ResourcesHero;
