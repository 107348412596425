import React from 'react';
import { Flex } from 'rebass';
import styled from 'styled-components';

const AnimatedDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  appearance: none;
  font-size: 16px;
  font-weight: 700;
  outline: none;
  cursor: pointer;

  min-height: 58px;

  border: 2px solid transparent;
  background-origin: border-box;
  background-clip: content-box, border-box;
  border-radius: 100px;
  background-color: white;
  background-image: linear-gradient(to right, white, white), linear-gradient(to right, #00a5c8, #00c8f5, #00a5c8);
  background-size: 300% 100%;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-position: 100% 0;
    transition: all 0.3s ease-in-out;
  }

  div {
    padding: 16px 32px;
    background: linear-gradient(to right, #00a5c8, #00c8f5, #00a5c8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 300% 100%;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-position: 100% 0;
      transition: all 0.3s ease-in-out;
    }
  }
`;

const AnimatedButtonBig = ({ children }) => (
  <Flex>
    <AnimatedDiv>
      <div>{children}</div>
    </AnimatedDiv>
  </Flex>
);

export default AnimatedButtonBig;
